<template>
  <div style="display: flex; align-items: center; justify-content: center; height: 100vh; flex-direction: column;" id="play-container" class="bg-yellow">
      <center>
            <button v-show="!go && !playing" @click="startStream" class="startButton">
                <svg xmlns="http://www.w3.org/2000/svg" width="6rem" height="6rem" fill="currentColor" class="bi bi-play-fill" viewBox="0 0 16 16">
                    <path d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z"/>
                </svg>
            </button>

            <div v-if="!endStream" class="display: flex; align-items: center; justify-content: center;">

                <div style="padding:56.25% 0 0 0; width: 150vh; max-width: 80vw; position: relative; border: 3px solid black;" id="player">
                    <vueVimeoPlayer 
                        ref="player" 
                        :video-id="videoID" 
                        @timeupdate="timeupdate" 
                        
                        @play="playing = true; go = true;"
                        @pause="playing = false"

                        @volumechange="volumeChange"

                        @ready="videoReady"
                        :options="playerOptions"
                        :autoplay="true"
                        :responsive="true"
                    />

                    <transition name="fade">
                        <div class="superQuestion" v-if="showingQuestion && this.currentQuestionId">
                            <div class="superQuestionQ">
                                {{ message }}
                            </div>
                            <div>
                                <div style="display: flex; width: 100%;" v-if="currentQuestionId < 5">
                                    <a class="col-6 questionAnswer" @click="answerQuestion('A', currentQuestionId)"  href="#">
                                        <span v-show="currentQuestionId == 1" class="img-button" style="background-image: url(/quiz/1a.png)" width="100%"></span>
                                        <span v-show="currentQuestionId == 2" class="img-button" style="background-image: url(/quiz/2a.png)" width="100%"></span>
                                        <span v-show="currentQuestionId == 3" class="img-button" style="background-image: url(/quiz/3a.png)" width="100%"></span>
                                        <span v-show="currentQuestionId == 4" class="img-button" style="background-image: url(/quiz/4a.png)" width="100%"></span>
                                    </a>
                                    <a class="col-6 questionAnswer" @click="answerQuestion('B', currentQuestionId)"  href="#">
                                        <span v-show="currentQuestionId == 1" class="img-button" style="background-image: url(/quiz/1b.png)" width="100%"></span>
                                        <span v-show="currentQuestionId == 2" class="img-button" style="background-image: url(/quiz/2b.png)" width="100%"></span>
                                        <span v-show="currentQuestionId == 3" class="img-button" style="background-image: url(/quiz/3b.png)" width="100%"></span>
                                        <span v-show="currentQuestionId == 4" class="img-button" style="background-image: url(/quiz/4b.png)" width="100%"></span>
                                    </a>
                                </div>

                                <div v-else-if="!kleurplaat">
                                    <a href="kleurplaat C&A.pdf" download target="_blank" @click="downloadKleurplaat">
                                        <button class="download-button" style="padding: 10px 30px; border-radius: 5px;">download je presentje</button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </transition>
                <div>    
        <button 
            v-if="!mobile"
            @click="goFullScreen" 
            :disabled="!fullscreenEnabled"
            class="bg-light-orange"
            id="gofullscreen"
        >

    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 508 508" style="enable-background:new 0 0 508 508;" xml:space="preserve" height="16" v-if="fullscreenEnabled">
        <g>
            <g>
                <path d="M419.2,0h-95.6C306,0,292,14.4,292,31.6c0,17.6,14.4,31.6,31.6,31.6H398L293.6,168.4c-12.4,12.4-12.4,32.8,0,45.2
                    c6,6,14,9.6,22.4,9.6c8.4,0,17.2-3.2,23.2-9.2l105.2-104.4v74c0,17.6,14.4,31.6,31.6,31.6c17.6,0,31.6-14.4,31.6-31.6V88
                    C508,40,467.2,0,419.2,0z"/>
            </g>
        </g>
        <g>
            <g>
                <path d="M211.2,168.4L106.8,63.6h74c17.6,0,31.6-14.4,31.6-31.6c0-17.6-14.4-31.6-31.6-31.6H85.6c-48,0-85.6,40-85.6,88v95.2
                    c0,17.2,14.4,31.6,31.6,31.6c17.6,0,31.6-14.4,31.6-31.6v-74.4l103.6,104.4c6,6,13.6,9.2,22,9.2c8.4,0,16.4-3.2,22.4-9.2
                    C223.6,201.2,223.6,180.8,211.2,168.4z"/>
            </g>
        </g>
        <g>
            <g>
                <path d="M476.4,294.4c-17.6,0-31.6,14.4-31.6,31.6v74L339.2,296c-6-6-14.4-9.2-22.8-9.2c-8.4,0-16.8,3.2-22.8,9.2
                    c-12.4,12.4-12.4,32,0,44.4l104.4,104h-74.4c-17.6,0-31.6,14.4-31.6,31.6c0,17.6,14.4,31.6,31.6,31.6h95.6
                    c48,0,88.8-38.4,88.8-86.4v-94.8C508,308.8,493.6,294.4,476.4,294.4z"/>
            </g>
        </g>
        <g>
            <g>
                <path d="M181.2,444.8h-74.4l104.4-104c12.4-12.8,12.4-32.4,0-44.8c-6-6-14-9.2-22.4-9.2c-8.4,0-15.6,3.2-21.6,9.2L63.6,400.4v-74
                    c0-17.6-14.4-31.6-31.6-31.6c-17.6,0-31.6,14.4-31.6,31.6v95.2C0.4,469.6,38,508,86,508h95.2c17.6,0,31.6-14.4,31.6-31.6
                    C212.8,458.8,198.4,444.8,181.2,444.8z"/>
            </g>
        </g>
    </svg>

<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16" v-else style="color: #FFC80B;">
  <path d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z"/>
</svg>
    </button>
    </div>
      </div>

    </div>


<!-- if stream is over -->
    <div v-else>
        <img src="NijntjeSlide.003.png" style="max-width: 60%;" alt="de stream is voorbij" />
        <br>
        <br>
        
        <a href="kleurplaat C&A.pdf" download target="_blank" @click="downloadKleurplaat">
            <button class="download-button" style="padding: 10px 30px; border-radius: 5px;">download je presentje</button>
        </a> 
    </div>

  </center>

  <div style="position: fixed; text-align: center; width: 20vh; max-width: 200px; bottom: 0px;" class="bg-yellow mobilequestion" v-if="mobile && running && showingMobileQuestion">
      <span v-if="currentQuestionId < 5">
        vraag {{ currentQuestionId }}:
      </span>
    <div style="display: flex; width: 100%;" v-if="currentQuestionId < 5">
            <a class="col-6 questionAnswer" style="padding: 3px margin-right: 40px;" @click="answerQuestion('A', currentQuestionId)" href="#">
                <span v-show="currentQuestionId == 1 && !answers[1]" class="img-button2" style="background-image: url(/quiz/1a.png)" width="50px"></span>
                <span v-show="currentQuestionId == 2 && !answers[2]" class="img-button2" style="background-image: url(/quiz/2a.png)" width="100%"></span>
                <span v-show="currentQuestionId == 3 && !answers[3]" class="img-button2" style="background-image: url(/quiz/3a.png)" width="100%"></span>
                <span v-show="currentQuestionId == 4 && !answers[4]" class="img-button2" style="background-image: url(/quiz/4a.png)" width="100%"></span>
            </a>
            <a class="col-6 questionAnswer" style="padding: 3px;" @click="answerQuestion('B', currentQuestionId)"  href="#">
                <span v-show="currentQuestionId == 1 && !answers[1]" class="img-button2" style="background-image: url(/quiz/1b.png)" width="100%"></span>
                <span v-show="currentQuestionId == 2 && !answers[2]" class="img-button2" style="background-image: url(/quiz/2b.png)" width="100%"></span>
                <span v-show="currentQuestionId == 3 && !answers[3]" class="img-button2" style="background-image: url(/quiz/3b.png)" width="100%"></span>
                <span v-show="currentQuestionId == 4 && !answers[4]" class="img-button2" style="background-image: url(/quiz/4b.png)" width="100%"></span>
            </a>
        </div>
        <div v-else>
            <a href="kleurplaat C&A.pdf" download target="_blank" @click="downloadKleurplaat">
                <button class="download-button" style="padding: 10px 30px; border-radius: 5px;">download je presentje</button>
            </a>
        </div>
  </div>
 </div> 

</template>

<script>

import { vueVimeoPlayer } from 'vue-vimeo-player'
import axios from 'axios';

import { isMobileOnly } from 'mobile-device-detect';

export default {
    components: {
        vueVimeoPlayer
    },

    props: {
        name: {
            type: String
        },
        sirname: {
            type: String
        },
        email: {
            type: String,
        },
        allowedToWatch: {
            type: Boolean
        },
        allowedToSendEmail: {
            type: Boolean
        }
    },

    data() {
        return {
            go: true,
            kleurplaat: false,

            // lil kraney
            // videoID: "314811197",

            // real end stream
            videoID: "564579504",

            // 10 voor 3, 16 seconde op vrijdag real event!
            // 14:50:15
            startUnix: 1624020615,

            // vanaf begin
            // startUnix: Math.floor(new Date().getTime() / 1000),

            playerOptions: {
                color: "ffe433",
                transparent: true,
                muted: false,
                autoplay: true,
                playsinline: true,
                allowfullscreen: false
            },

            playing: false,
            muted: false,
            volume: 0,
            fullscreen: false,
            fullscreenEnabled: true,
            running: false,

            mobile: isMobileOnly ? true : false,

            responseSaved: false,
            saving: false,

            message: 'welk jurkje heeft nijntje aan?',
            status: "welcome",
            answers: {
                1: null,
                2: null,
                3: null,
                4: null,
                5: null,
            },

            currentQuestionId: 1,
            showingQuestion: false,
            showingMobileQuestion: false,

            questions: {
                1: {
                    text: 'welk jurkje heeft nijntje aan?',
                    starttime: 953,
                    endtime: 968
                    // starttime: 5,
                    // endtime: 12
                },
                2: {
                    text: 'wat is gezonder voor je?',
                    starttime: 1160,
                    endtime: 1175
                    // starttime: 15,
                    // endtime: 20
                },
                3: {
                    text: 'welke letter is dit?',
                    starttime: 1185,
                    endtime: 1200
                    // starttime: 20,
                    // endtime: 25
                },
                4: {
                    text: 'wat is beter voor de aarde?',
                    starttime: 1388,
                    endtime: 1403
                    // starttime: 30,
                    // endtime: 35
                },
                5: {
                    text: '',
                    starttime: 1838,
                    endtime: 2100
                    // starttime: 60,
                    // endtime: 75
                }
            },
            confirmation: {
                allowWatch: false
            },
            endStream: false
        }
    },

    computed: {
        welcomeMessage() {
            return 'welkom op mijn verjaardag ' + this.name
        },

        currentQuestion() {
            return this.questions[this.currentQuestionId]
        },

        finished() {
            return !Object.values(this.answers).includes(null)
        },

        quizDone() {
            return this.answers[1] && this.answers[2] && this.answers[3] && this.answers[4]
        }
    },

    created() {
        let resp = localStorage.getItem('responseSaved')
        if(resp && resp == "true") {
            this.responseSaved = true
        } else {
            this.responseSaved = false
        }
    },

    watch: {
        confirmation: {
            handler: function (newVal, ov) {
                console.log(newVal, ov)
                localStorage.setItem('confirmation', JSON.stringify(newVal));
            },
            deep: true
        }
    },

    mounted() {

        console.log(this.startUnix);
        /* If fullscreen mode is available, then do something */
        if (
            document.fullscreenEnabled || /* Standard syntax */
            document.webkitFullscreenEnabled || /* Safari */
            document.msFullscreenEnabled/* IE11 */
        ) {
            this.fullscreenEnabled = true
            console.log('fullscreen enabled')
        } else {
            this.fullscreenEnabled = false;
            console.log('fullscreen disabled')
        }

        let currentTime = Math.floor(new Date().getTime() / 1000);

        if(currentTime > this.startUnix + 2340) {
            console.log('stream is over');
            this.endStream = true
        } else {
            let untilEnd = (this.startUnix + 2340 - currentTime) * 1000;
            console.log('stream ending in: ' + (untilEnd / 1000) + ' seconds');
            setTimeout(() => {
                this.endStream = true;
            }, untilEnd)
        }   

        this.answers[1] = localStorage.getItem('q1') || null
        this.answers[2] = localStorage.getItem('q2') || null
        this.answers[3] = localStorage.getItem('q3') || null
        this.answers[4] = localStorage.getItem('q4') || null
    },

    methods: {
        startStream() {
            this.play();
            this.unmute();
            this.go = true;
        },

        playend() {
            console.log('playing');
        },

        volumeChange(x) {
            this.volume = x.volume
            if(x.volume == 0) {
                this.muted = true
            } else {
                this.muted = false
            }
        },

        downloadKleurplaat() {
            console.log('downloading');
            this.kleurplaat = true;
            this.showingQuestion = false;
        },

        closeQuestion() {
            this.currentQuestionId = null;
        },

        play () {
			this.$refs.player.play()
		},

		pause () {
			this.$refs.player.pause()
		},

        mute () {
            this.$refs.player.mute()
        },

        unmute() {
            this.$refs.player.unmute()
        },

        videoReady(x) {
            console.log(x)
            console.log('video ready')
            this.play()
            this.unmute()
        },

        timeupdate(value){
            // console.log(value.seconds);

            if (this.running == false) {
                this.running = true
            }

            // Emitted from the player every half a second or so
            if (!this.mobile) {
                if (value.seconds > this.questions[1].starttime && value.seconds < this.questions[1].endtime && !this.answers[1]) {
                    if(!this.showingQuestion) {
                        this.showingQuestion = true
                    }
                    this.currentQuestionId = 1
                    this.message = this.questions[1].text
                    console.log('Q1 NU')
                } else if (value.seconds > this.questions[2].starttime && value.seconds < this.questions[2].endtime && !this.answers[2]) {
                    if(!this.showingQuestion) {
                        this.showingQuestion = true
                    }
                this.currentQuestionId = 2
                    this.message = this.questions[2].text
                    console.log('Q2 NU') 
                } else if (value.seconds > this.questions[3].starttime && value.seconds < this.questions[3].endtime && !this.answers[3]) {
                    if(!this.showingQuestion) {
                        this.showingQuestion = true
                    }
                    this.currentQuestionId = 3
                    this.message = this.questions[3].text
                    console.log('Q3 NU') 
                } else if (value.seconds >  this.questions[4].starttime && value.seconds < this.questions[4].endtime && !this.answers[4]) {
                    if(!this.showingQuestion) {
                        this.showingQuestion = true
                    }
                this.currentQuestionId = 4
                    this.message = this.questions[4].text
                    console.log('Q4 NU') 
                } else if (value.seconds > (this.questions[4].endtime + 20) && value.seconds < (this.questions[4].endtime + 30) && !this.responseSaved && !this.saving) {
                    this.saving = true;
                    this.submitResults();
                } else if (value.seconds > this.questions[5].starttime && !this.kleurplaat) {
                    this.currentQuestionId = 5
                    this.message = ''
                    this.showingQuestion = true
                } else {
                    this.showingQuestion = false;
                }
            } else {
                // current time as unix timestamp
                let currentTime = Math.floor(new Date().getTime() / 1000);
                // console.log(currentTime)

                if (    currentTime > (this.startUnix + this.questions[1].starttime) && 
                        currentTime < (this.startUnix + this.questions[1].endtime + 20) &&
                        !this.answers[1]
                    ) {
                    this.showingMobileQuestion = true
                }

                else if (    currentTime > (this.startUnix + this.questions[2].starttime ) &&
                        currentTime < (this.startUnix + this.questions[3].endtime + 20) &&
                        (!this.answers[2] || !this.answers[3])
                        ) {
                            if(this.currentQuestionId == 1) {
                                this.currentQuestionId = 2
                            }
                            
                    this.showingMobileQuestion = true
                }
                else if(    currentTime > (this.startUnix + this.questions[4].starttime) &&
                            currentTime < (this.startUnix + this.questions[4].endtime +20)  
                            && !this.answers[4]
                        ) {
                    this.showingMobileQuestion = true
                } else if (
                    currentTime > (this.startUnix + this.questions[4].endtime + 25) &&  !this.responseSaved && !this.saving
                ) {
                    this.submitResults();
                    this.currentQuestionId = 5
                } else if(
                    currentTime > (this.startUnix + this.questions[5].starttime + 8) &&
                    !this.kleurplaat
                ) {
                    this.currentQuestionId = 5
                    this.showingMobileQuestion = true
                } else {
                    this.showingMobileQuestion = false
                }
            }
        },

        answerQuestion(answer, question) {
            if(question == this.currentQuestionId) {
                this.answers[this.currentQuestionId] = answer;
                localStorage.setItem('q'+this.currentQuestionId, answer);
    
                console.log(answer)
                this.currentQuestionId++;

                if (this.currentQuestionId == 5) {
                    this.submitResults()
                }

                this.message = this.questions[this.currentQuestionId].text

                this.showingQuestion = false;
                this.showingMobileQuestion = false;
            }
        },
        
        getRandomSeconds(max) {
            return Math.floor(Math.random() * max);
        },

        submitResults() {
            this.saving = true
            if(!this.responseSaved || this.responseSaved ){
                let randomTime = this.getRandomSeconds(15) * 1000
                console.log('sending result in ' + randomTime / 1000 + 'seconds');
                console.log({
                     name: this.name,
                     sirname: this.sirname,
                     email: this.email,
                     allowedToSendEmail: this.allowedToSendEmail,
                     q1: this.answers[1],
                     q2: this.answers[2],
                     q3: this.answers[3],
                     q4: this.answers[4]
                })
                setTimeout(
                    () => {
                    axios.post('https://sad-ganguly.185-95-14-132.plesk.page/answers.php', {
                        name: this.name,
                        sirname: this.sirname,
                        email: this.email,
                        allowedToSendEmail: this.allowedToSendEmail,
                        q1: this.answers[1],
                        q2: this.answers[2],
                        q3: this.answers[3],
                        q4: this.answers[4]
                    }).then(() => {
                        console.log('response successfully handled');
                        localStorage.setItem('responseSaved', true);
                        this.responseSaved = true;
                    }).catch((error) => {
                        console.log('something is wrong, trying again')
                        this.status = error
                        this.submitResults();
                    })
                    }, randomTime)
                } else {
                    this.status = 'response al naar server gestuurd'
            }
        },

        goFullScreen(){
            if(!this.fullscreen){
                var elem = document.getElementById("play-container");
                var requestFullScreen = elem.requestFullscreen || elem.msRequestFullscreen || elem.mozRequestFullScreen || elem.webkitRequestFullscreen;
                requestFullScreen.call(elem)
                .then(()  => {
                    this.fullscreen = true
                    console.log('entered fullscreen');
                    // element has entered fullscreen mode successfully
                })
                .catch((error) => {
                    alert('Your browser doesn\'t support this mode...');
                    this.fullscreenEnabled = false;
                    console.log(error.message);
                });
            } else {
                this.closeFullscreen();
            }
        },

        closeFullscreen() {
            if (document.exitFullscreen) {
                document.exitFullscreen();
                this.fullscreen = false
            } else if (document.webkitExitFullscreen) { /* Safari */
                document.webkitExitFullscreen();
                this.fullscreen = false
            } else if (document.msExitFullscreen) { /* IE11 */
                document.msExitFullscreen();
                this.fullscreen = false
            } else {
                this.fullscreenEnabled = false;
                console.log('cannot exit fullscreen?')
            }
        }
    }
}
</script>

<style>

.questionAnswer:hover {
    background-color: #FFC80B ;
    cursor: pointer;
}

img {
    transition: all 0.25s;
    opacity: 1;
}

.questionAnswer:hover > img {
    -ms-transform: rotate(7deg); /* IE 9 */
    -webkit-transform: rotate(7deg); /* Chrome, Safari, Opera */
    transform: rotate(7deg);
}

iframe {
     position:absolute;top:0;left:0;width:100%;height:100%;
}

@media only screen and (max-width: 600px) {
    #player {
        max-width: 100vw!important;
    }
    .superQuestion {
        width: 80vw!important;
        margin-left: 10vw!important;
        height: auto!important;
    }
}

.mobilequestion {
    width: 40vw!important;
    max-width: 300px!important;
    box-shadow: 1px -10px 23px -2px rgba(0,0,0,0.54);
    -webkit-box-shadow: 1px -10px 23px -2px rgba(0,0,0,0.54);
    -moz-box-shadow: 1px -10px 23px -2px rgba(0,0,0,0.54);    
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    min-width: 120px!important;
}

.superQuestion {
    z-index: 999;
    position: fixed;
    left: 0px;
    bottom: 0px;
    height: auto;
    width: 22vw;
    margin-left: 39vw;
    background-color: #FFC80B;
    padding:1.2em;
    border-top-left-radius: 3rem;
    border-top-right-radius: 3rem;
    box-shadow: 1px -10px 23px -2px rgba(0,0,0,0.54);
    -webkit-box-shadow: 1px -10px 23px -2px rgba(0,0,0,0.54);
    -moz-box-shadow: 1px -10px 23px -2px rgba(0,0,0,0.54);
    justify-content: center;
}

.questionAnswer {
    text-align: center;
    background-color: #FFC80B;
}

.startButton {
    position: Fixed;
    top: 40vh;
    width: 40vw;
    left: 30vw;
    height: 20vh;
    z-index: 999;
    background-color: #FD8429; 
}

#gofullscreen {
    position: relative;
    float: right;
    top: 4px;
    width: 40px;
    height: 40px;
    left: 2px;
    background-color: #ffc80b;
}



.img-button {
        display: inline-block; 
        background-size: cover;
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 100%;
}

.img-button:hover, .img-button2:hover {
    opacity: .5;
}

.img-button2 {
        display: inline-block; 
        background-size: cover;
        position: relative;
        width: 50%;
        height: 0;
        padding-bottom: 50%;
}

.fade-enter-active {
  transition: opacity .5s;
}

.fade-leave-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.download-button {
    background-color: white;
}

.download-button:hover {
    opacity: .8;
}

</style>
