<template>
  <div >
    <LiveStream v-if="startStream" :name="name" :email="email" :sirname="sirname" :allowedToSendEmail="allowedToSendEmail" />
    <StartScreen v-else @formsubmitted="startScreenSubmitted" />
  </div>
</template>

<script>
import StartScreen from './StartScreen.vue'
import LiveStream from './Livestream.vue'

export default {
  name: 'Application',

  components: {
    StartScreen,
    LiveStream
  },

  computed: {
      startStream() {
        if(this.allowedToWatch) {
            return true 
        } else {
            return false
        }
      }
  },

  created() {
      this.name = localStorage.getItem('name') || null
      this.sirname = localStorage.getItem('sirname') || null
      this.email = localStorage.getItem('email') || null
      this.allowedToWatch = JSON.parse(localStorage.getItem('allowedToWatch')) || false
      this.allowedToSendEmail = JSON.parse(localStorage.getItem('allowedToSendEmail')) || false
  },

  methods: {
    startScreenSubmitted(dataFields) {
      console.log(dataFields)
      this.allowedToWatch = dataFields.allowedToWatch
      this.allowedToSendEmail = dataFields.allowedToSendEmail
      this.name = dataFields.name
      this.sirname = dataFields.sirname
      this.email = dataFields.email

      localStorage.setItem('name', this.name);
      localStorage.setItem('sirname', this.sirname);

      localStorage.setItem('email', this.email);
      localStorage.setItem('allowedToWatch', this.allowedToWatch);
      localStorage.setItem('allowedToSendEmail', this.allowedToSendEmail);
    }
  },

  data() {
    return {
        name: null,
        email: null,
        allowedToWatch: false,
        allowedToSendEmail: false,
        sirname: null
      }
    }
  }

</script>

<style>
html {
  
}
</style>
