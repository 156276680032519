<template>
  <div id="app">
    <Page />

    <div id="preloadedImages"></div>

  </div>
</template>

<script>
import Page from './components/Page.vue'

export default {
  name: 'App',

  components: {
    Page,
  }

}
</script>

<style>
html, html {
  background-color: #FFC80B;
}

#preloadedImages
{
    width: 0px;
    height: 0px;
    display: inline;
    background-image: url(/quiz/1a.png), url(/quiz/1b.png), url(/quiz/2a.png), url(/quiz/2b.png),  url(/quiz/3a.png),url(/quiz/3b.png), url(/quiz/4a.png), url(/quiz/4b.png);
}
</style>
