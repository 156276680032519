<template>
     <div class="bg-yellow">
         <div>
            <div style="flex: 1 0 auto; padding: 2%; text-align: left;">
                    <div style="display: flex;  align-items: center;">        
                <div class="flex: 1;">
                <img src="Logo_C&A_Black.png" height="60px" style="float: left; margin-left: 40px;">
                </div>
                <h5 style="flex:3;">
                organiseert
            </h5>
        </div>


        </div>
         <h1 class="text-center" >
            nijntjes surprise party!
         </h1>

<hr>
        <div class="row" style="max-width: 99%;">
            <div class="col-md-6 p-5">
                <img src="../assets/nijn.svg" alt="nijn" style="max-width: 80%; margin: auto;" class="nijn-img"/>
            </div>

            <div class="col-md-6 bg-yellow p-5">
                <form @submit.prevent="submitForm">
                    <h3>
                        nijntje wil je een aantal vragen stellen
                    </h3>
                        je kunt dan meedoen met de quiz en maakt kans op mooie nijntje-prijzen
                    <br> <br>
                <div>
                    Wat is je voornaam: <br>
                    <input type="text" v-model="name" class="form-control" style="max-width: 90%">
                </div>
                <div>
                    Wat is je achternaam: <br>
                    <input type="text" v-model="sirname" class="form-control" style="max-width: 90%">
                </div>

                <div>
                    Wat is je e-mailadres? <br><i style="font-size: .9rem;">zo kunnen we je bereiken als je een prijs gewonnen hebt</i><br>
                    <input type="email" v-model="email" class="form-control" style="max-width: 90%">
                </div>
<!-- 
                <div>
                    Mogen we je eenmalig een email sturen na afloop? <br>
                    <input type="checkbox" v-model="allowedToSendEmail" height="20px" width="20px">
                </div> -->

                <!-- <div>
                    Mag je kijken van je ouders?<br>
                    <input type="checkbox" v-model="allowedToWatch" required>
                </div> -->

                <input type="submit" class="btn btn-lg btn-primary" value="naar het feestje">
                <br>

                <div>
                    <a href="https://www.nijntje.nl/disclaimer-privacy-statement" target="blank">
                        privacy verklaring
                    </a>
                </div>

            </form>
            </div>

        </div>

        <div style="text-align: center;" class="footer">
              Illustraties Dick Bruna © copyright Mercis bv, 1953-2021
        </div>

        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            name: null,
            sirname: null,
            allowedToWatch: true,
            email: null,
            allowedToSendEmail: true
        }
    },

    computed: {
        readyToStart() {
            return this.allowedToWatch && this.name && this.sirname && this.email
        }
    },

    methods: {
        submitForm() {
            this.$emit('formsubmitted', {
                name: this.name,
                sirname: this.sirname,
                allowedToWatch: this.allowedToWatch,
                email: this.email,
                allowedToSendEmail: this.allowedToSendEmail
            })
        }
    }
}
</script>

<style scoped>

body {
    margin: 0px;
    box-sizing:border-box;
}
.nijn-img {
     height: 60vh;
 }

 form > div {
     /* border-bottom: 1px solid black; */
     padding: 5px 0px;
 }

.footer {
}

    @media only screen and (max-width: 600px) {

        .nijn-img {
            max-height: 200px!important;
        }

        .footer {
            font-size: .6em!important;
        }
    }



</style>